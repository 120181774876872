type MarketAssetInformations = {
  description: string
  whitepaperUrl?: string
  pitchDeckUrl?: string
}

export const marketAssetInformations: { [key in string]: MarketAssetInformations } = {
  'BTC': {
    description: 'Bitcoin es la primera y más conocida criptomoneda, diseñada para ofrecer un ' +
                 'sistema de pago global sin la necesidad de intermediarios como bancos. Los ' +
                 'usuarios pueden enviar y recibir pagos directamente entre sí de manera rápida ' +
                 'y segura, utilizando la tecnología blockchain que asegura la transparencia y ' +
                 'la inmutabilidad de las transacciones. Bitcoin ha ganado popularidad como una ' +
                 'reserva de valor digital y es considerada "oro digital" por muchos.',
    whitepaperUrl: 'https://legal-documents-storage.s3.us-east-1.amazonaws.com/bitcoin-whitepaper.pdf',
  },
  'ETH': {
    description: 'Ethereum es una plataforma blockchain que no solo permite transacciones ' +
                 'seguras, sino también la creación de contratos inteligentes y aplicaciones ' +
                 'descentralizadas (dApps). A diferencia de Bitcoin, que está más enfocado en ' +
                 'ser una moneda digital, Ethereum es una infraestructura para desarrolladores, ' +
                 'permitiendo construir y ejecutar proyectos en su red. Ether es la criptomoneda ' +
                 'utilizada para operar dentro de la red Ethereum.',
    whitepaperUrl: 'https://legal-documents-storage.s3.us-east-1.amazonaws.com/ethereum-whitepaper.pdf',
  },
  'ADA': {
    description: 'Cardano es una plataforma de blockchain centrada en la seguridad y la ' +
                 'sostenibilidad, construida sobre investigaciones científicas y revisada por ' +
                 'pares. Está diseñada para ofrecer contratos inteligentes y transacciones ' +
                 'rápidas y económicas, utilizando un enfoque más ecológico que muchas otras ' +
                 'blockchains. ADA es la criptomoneda que impulsa esta plataforma, permitiendo ' +
                 '¡a los usuarios realizar pagos y ejecutar aplicaciones en la red.',
    whitepaperUrl: 'https://legal-documents-storage.s3.us-east-1.amazonaws.com/cardano-whitepaper.pdf',
  },
  'DOT': {
    description: 'Polkadot es una criptomoneda que busca conectar múltiples blockchains en una ' +
                 'sola red, permitiendo que diferentes sistemas puedan intercambiar información ' +
                 'y operar juntos de manera fluida. Con Polkadot, los desarrolladores pueden ' +
                 'crear sus propias blockchains específicas para sus necesidades y conectarlas ' +
                 'con otras, lo que facilita la interoperabilidad en el mundo de las ' +
                 'criptomonedas.',
    whitepaperUrl: 'https://legal-documents-storage.s3.us-east-1.amazonaws.com/polkadot-whitepaper.pdf',
  },
  'SOL': {
    description: 'Solana es una blockchain de alto rendimiento diseñada para soportar ' +
                 'transacciones rápidas y económicas. A diferencia de otras blockchains, ' +
                 'Solana utiliza un mecanismo único de consenso que le permite procesar miles ' +
                 'de transacciones por segundo. Es ideal para aplicaciones descentralizadas y ' +
                 'criptomonedas que requieren gran escalabilidad, con SOL como la criptomoneda ' +
                 'utilizada en su ecosistema.',
    whitepaperUrl: 'https://legal-documents-storage.s3.us-east-1.amazonaws.com/solana-whitepaper.pdf',
  },
  'USDT': {
    description: 'Tether (USDT) es una criptomoneda vinculada al valor del dólar estadounidense, ' +
                 'lo que significa que cada USDT equivale aproximadamente a 1 USD. Es ' +
                 'ampliamente utilizada por quienes desean proteger sus activos contra la ' +
                 'volatilidad de otras criptomonedas, mientras mantienen los beneficios de las ' +
                 'transacciones rápidas y seguras en la blockchain.',
    whitepaperUrl: 'https://legal-documents-storage.s3.us-east-1.amazonaws.com/usdt-whitepaper.pdf',
  },
  'USDC': {
    description: 'USD Coin (USDC) es una criptomoneda estable que está respaldada en una ' +
                 'proporción de 1:1 con el dólar estadounidense. Esto la convierte en una opción ' +
                 'confiable para quienes desean mantener sus fondos en criptomonedas sin ' +
                 'exponerse a las fluctuaciones de precios del mercado. USDC se utiliza ' +
                 'principalmente para pagos digitales y transferencias rápidas de valor entre ' +
                 'usuarios de todo el mundo.',
    whitepaperUrl: 'https://legal-documents-storage.s3.us-east-1.amazonaws.com/usdc-whitepaper.pdf',
  },
  'ZRF': {
    description: 'ZURF es un proyecto de redes sociales centrado en la Web3 que busca ' +
                 'revolucionar la interacción en línea mediante la creación de experiencias ' +
                 'divertidas, monetizadas y relacionales para los usuarios. A diferencia de ' +
                 'las plataformas tradicionales, ZURF permite a los usuarios interactuar, ' +
                 'ganar dinero y establecer relaciones sin salir de su feed, aprovechando ' +
                 'tecnologías de blockchain para asegurar la propiedad de contenido y ' +
                 'transacciones seguras.',
    whitepaperUrl: 'https://legal-documents-storage.s3.us-east-1.amazonaws.com/zurf-whitepaper.pdf',
    pitchDeckUrl: 'https://legal-documents-storage.s3.us-east-1.amazonaws.com/zurf-pitch-deck.pdf',
  },
}
